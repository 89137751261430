import React, { useState, useEffect } from "react";
import Card from '../../composants/Card/Card';
import './projects.css';
import * as data from '../../services/Data/projects';
import { useHistory } from "react-router-dom";

export default function Projects() {

    const history = useHistory();
    const handleOnClickToProjectDetails = (id: number) => history.push('/projects/'+id);

    const [width, setWidth] = useState(window.innerWidth);
    const [heightCard, setHeightCard] = useState(goodCardSize(false));
    const [heightPresentationSites, setHeightPresentationSites] = useState(goodCardSize(true));

   function goodCardSize(presentation:boolean) : string {
        if(window.innerWidth > 897){
            if(presentation){
                return "600px";
            }
           return "700px";
        }
        if(window.innerWidth > 500){
            if(presentation){
                return "400px";
            }
            return "650px";
        }
        else{
            if(presentation){
                return "300px";
            }
            return"500px";
        }
    }
    
    const updateWidthAndHeight = () => {
        setWidth(window.innerWidth);
        setHeightCard(goodCardSize(false));
        setHeightPresentationSites(goodCardSize(true));
    };

    useEffect(() => {
        window.addEventListener("resize", updateWidthAndHeight);
        return () => {window.removeEventListener("resize", updateWidthAndHeight);};
    },[updateWidthAndHeight]);

    


    const listProjects = data.PROJECTS.map((project) => 
        <div className="content_presentation">
            <div className="row_presentation">
                <input className="project_image" type="image" onClick={()=>handleOnClickToProjectDetails(project.id)} src={"/assets/img/projects/"+ project.id+"/main.jpg"}/>
                <button className="project_title" onClick={() => project.lien ? window.location.href = "" + project.lien : "" }>{project.name}</button>
            </div>
            <p className="text_presentation">{project.description}</p>
        </div>
     );

    let projectsContent:any = (
        <div style={{height:heightPresentationSites}} className="project_list vertical-center">
            {listProjects}
        </div>
    );
    return (
       <Card titleCategory="PROJETS" iconName="laptop" cardSizeHeight={heightCard} content={projectsContent}/>
    );
}